// import { URIPrivacyPolicy, URIUserAgreement } from "constants/uri";
import { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from '../page/Home'
import Historical from '../page/Historical'
import Payment from "../page/Payment";
import Doc from "../page/Doc";
import Article from "../page/Article";
import ArticleList from "../page/ArticleList";
import UseScenes from "../page/UseScenes";
import Price from "../page/Price";
import PdfSmaller from "../page/PdfSmaller";
import Faq from "../page/Faq";
import Api from "../page/Api";
import NotFound from "../page/Error/Error";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Suspense fallback={<div />} children={ <Home />} />,
    children: [],
  },
  {
    path: "/historical",
    element: <Historical/>,
  },
  {
    path:"/payment",
    element:<Payment/>
  },
  {
    path:"/doc",
    element:<Doc/>
  },
  {
    path:"/article/:index/:id",
    element:<Article/>
  },
  {
    path:"/register",
    element:<Home/>
  },
  {
    path:"/articles",
    element:<ArticleList/>
  },
  {
    path:"/users/:id",
    element:<UseScenes/>
  },
  {
    path:"/pdf-to-pdf-conversion",
    element:<PdfSmaller/>
  },
  {
    path:"/pricing",
    element:<Price/>
  },
  {
    path:"/faq",
    element:<Faq/>
  },
  {
    path:"/get-api",
    element:<Api/>
  },
  {
    path:"*",
    element:<NotFound/>
  }
]);
