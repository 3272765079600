import Layout from "antd/es/layout/layout";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { useNavigate } from "react-router-dom";
import { useUpload } from "../../components/FileUploadContext";
import React, { useRef } from "react";
import withMeta from "../../components/withMeta";
import styled from "styled-components";

export default function NotFound() {
    return (
        <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
            <HomeHeader />
            <p style={{ fontSize: "44px", textAlign: "center", marginTop: "50px", height: '32vh' }}>404 Not Found</p>
            <HomeFooter />
        </Layout>

    );

}


