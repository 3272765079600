import instance from "./http/http";

class TokenManager {

  isLogin:boolean = false
  private static instance: TokenManager;

  private constructor() { } // 私有构造函数，防止外部创建实例

  // 获取单例实例
  static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager();
    }
    return TokenManager.instance;
  }

  // 保存Google Token
  saveGoogleToken(token: string) {
    localStorage.setItem('google', token);
  }

  // 保存Apple Token
  saveAppleToken(token: string) {
    localStorage.setItem('apple', token);
  }

  // 保存Facebook Token
  saveFacebookToken(token: string) {
    localStorage.setItem('facebook', token);
  }

  // 保存Token
  saveToken(token: string) {
    localStorage.setItem('token', token);
  }

  // 保存邮箱
  saveEmail(email: string) {
    localStorage.setItem('email', email);
  }

  // 保存邮箱密码
  saveEmailPwd(pwd: string) {
    localStorage.setItem('email_pwd', pwd);
  }

  // 获取Token
  getToken(): string | null {
    return localStorage.getItem('token');
  }

  // 获取Google Token
  getGoogleToken(): string | null {
    return localStorage.getItem('google');
  }

  // 获取Facebook Token
  getFacebookToken(): string | null {
    return localStorage.getItem('facebook');
  }

  // 获取Apple Token
  getAppleToken(): string | null {
    return localStorage.getItem('apple');
  }

  // 获取邮箱
  getEmail(): string | null {
    return localStorage.getItem('email');
  }

  // 获取邮箱密码
  getEmailPwd(): string | null {
    return localStorage.getItem('email_pwd');
  }

  // 获取语言
  getLanguage(): string | null {
    return localStorage.getItem('language');
  }

  // 获取本地登录状态
  getLocalLogion(): boolean {
    return this.isLogin;
  }

  // 设置本地登录状态
  setLocalLogin(isLogin:boolean) {
    this.isLogin = isLogin
  }

  // 移除所有Token
  removeAllToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('google');
    localStorage.removeItem('facebook');
    localStorage.removeItem('apple');
    localStorage.removeItem('email')
    localStorage.removeItem('email_pwd')
    const interc = instance.interceptors.request.use((config) => {
      config.headers.Authorization = "";
      return config;
    });
    window.location.reload();
  }
}

export default TokenManager.getInstance(); // 导出单例实例