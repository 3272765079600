// src/Article.tsx
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import styled from "styled-components";
import { marked } from "marked";
import * as cheerio from 'cheerio';
import Layout from "antd/es/layout/layout";
import { Space } from "antd";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { calculateLayoutWidth, px2vw } from "../../libs/utils";
import { apiArticleGet } from "../../libs/http/api";
import withMeta from "../../components/withMeta";
import { t } from "../../languages";
import { useVoerkaI18n } from "@voerkai18n/react";

export default function Fqa() {
  const { id } = useParams();
  const article = useRequest(apiArticleGet, { manual: true });
  const { runAsync: articleGet } = article;
  const [markdown, setMarkdown] = useState<string>("");
  const [headings, setHeadings] = useState<string[]>([]);
  const [activeHeading, setActiveHeading] = useState<string | null>(null);
  const headingRefs = useRef<{ [key: string]: HTMLHeadingElement | null }>({});
  const { activeLanguage } = useVoerkaI18n();

  const path =
    activeLanguage === "zh" ? "/static/doc/fqa.md" : "/static/doc/fqa_en.md";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(path)
      .then((response) => response.text())
      .then((text) => {
        const html = marked(text).toString();
        setMarkdown(html);

        const $ = cheerio.load(html);
        const h2Headings = $("h3")
          .map((_, element) => $(element).text())
          .get();
        setHeadings(h2Headings);
      })
      .catch((error) =>
        console.error("Error fetching the Markdown file:", error)
      );
  }, [id]);

  const handleScroll = () => {
    const elements = document.querySelectorAll("h3");
    let currentActiveHeading: string | null = null;

    elements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      if (rect.top <= 128) {
        // 调整此值，88px为header高度，20px为sidebar的top偏移
        currentActiveHeading = element.textContent || null;
      }
    });

    setActiveHeading(currentActiveHeading);
  };

  const handleClick = (heading: string) => {
    const element = headingRefs.current[heading];
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 108, // 减去 header 的高度
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {withMeta({
        title: t("常见问题，图片OCR文本识别FAQ"),
        description: t(
          "在这里，我们列出了一些关于pdf到pdf转换的常见问题。如果您没有找到所需的信息，请随时通过pdftopdf@leqi.ai与我们联系。"
        ),
        keywords:
          "pdf to pdf conversion，pdf to pdf FAQ，image pdf to pdf converter online，convert pdf to pdf",
      })}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />
        <Main>
          <Title>Q&A</Title>
          <Space
            size={calculateLayoutWidth(16)}
            style={{ marginTop: px2vw(50) }}
          >
            {article?.data?.data.data.keywords.split(",").map((item) => (
              <KeyWords key={item}>{item}</KeyWords>
            ))}
          </Space>
          <Line />
          <Content>
            <Sidebar>
              <ContentTitle>
                {headings.map((heading, index) => (
                  <ContentItem
                    key={index}
                    isActive={heading === activeHeading}
                    onClick={() => handleClick(heading)}
                  >
                    {heading}
                  </ContentItem>
                ))}
              </ContentTitle>
            </Sidebar>
            <ContentMain>
              <div
                className="markdown-content"
                dangerouslySetInnerHTML={{ __html: markdown }}
                ref={(element) => {
                  if (element) {
                    const headings = element.querySelectorAll("h3");
                    headings.forEach((headingElement) => {
                      headingRefs.current[headingElement.innerText] =
                        headingElement;
                    });
                  }
                }}
              ></div>
            </ContentMain>
          </Content>
        </Main>
        <HomeFooter />
      </Layout>
    </>
  );
}

const Main = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  width: 1520px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    margin-top: 0px;
    padding: 102px;
  }
`;

const Line = styled.div`
  width: 1400px;
  height: 1px;
  background: #ebebeb;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Title = styled.label`
  width: 1320px;
  height: auto;
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 40px;
  color: #222222;
  line-height: 49px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 90%;
    font-size: 123px;
    line-height: 148px;
  }
`;

const Content = styled.div`
  width: 100%;
  /* font-family: Montserrat Light; */
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 36px;
  text-align: left;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 77px;
    line-height: 97px;
  }
`;

const KeyWordsFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 50px;

  @media only screen and (max-width: 768px) {
    gap: 40px;
    margin-top: 100px;
  }
`;

const KeyWords = styled.label`
  padding: 4px 8px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #373737;
  font-weight: 400;
  font-size: 20px;
  color: #373737;
  line-height: 28px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 57px;
    line-height: 77px;
    height: auto;
    border-radius: 20px;
    border: 5px solid #373737;
    padding: 16px;
  }
`;

const ContentTitle = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ContentItem = styled.li<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  font-size: 24px;
  color: #222222;
  line-height: 29px;
  margin-bottom: 30px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 97px;
    margin-bottom: 82px;
    color: #0d70e6;
  }
`;

const ContentMain = styled.div`
  flex: 1;
  padding-left: 40px;
  @media only screen and (max-width: 768px) {
    padding-left: 0px;
  }
`;

const Sidebar = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 769px) {
    width: 100%;
    position: sticky;
    top: 108px; /* 88px header + 20px margin */
    width: 240px;
    height: fit-content;
    align-self: flex-start; /* 确保 Sidebar 从顶部开始 */
  }
`;
