// src/Article.tsx
import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRequest } from "ahooks";
import styled from "styled-components";
// import { marked } from "marked";
// import cheerio from "cheerio";
import Layout from "antd/es/layout/layout";
import { Space, Modal, Flex, message } from "antd";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { calculateLayoutWidth, px2vw, mToPcPx } from "../../libs/utils";
import {
  apiGetKeySecret,
  apiGetTestKeySecret,
  apiGetB2bPaymentDetail,
  apiCreateB2bPaymentIntent,
  apiConfirmB2bPayment,
  apiApplyBill
 } from "../../libs/http/api";
import { isMobile } from "../../libs/utils";
import withMeta from "../../components/withMeta";
import { t } from "../../languages";
import { useVoerkaI18n } from "@voerkai18n/react";
import emitter from "../../libs/eventBus";
import { useAuth } from "../../components/AuthContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ApiCheckoutForm from "../Payment/ApiCheckoutForm";
import pay_sel from "../../assets/historical/pay_icon_sel@2x.png";
import con_icon_secceed from "../../assets/api/con_icon_secceed.png";


const stripePromise = loadStripe(
  "pk_live_51O6o1BDkoOhDlqLb4znpIYocgnDFVqiQuACFD6hiCaxz4gWMJUEe7B2YqNuIOdgFwyMSdHOdMzyMBnHtfurjFZMO000tMUZTtc"
);

// const stripePromise = loadStripe(
//   "pk_test_51O6o1BDkoOhDlqLbrAg1wrG6nFiRGzJhJ3mtVMCj4ysCFVSYWqRILaqYbQf6gHc4XBzYVVIOUWnJ059E2VenehtU00u7eSumiE"
// );

export default function Fqa() {
  const { id } = useParams();
  // const [markdown, setMarkdown] = useState<string>("");
  // const [headings, setHeadings] = useState<string[]>([]);
  const [activeHeading, setActiveHeading] = useState<string | null>('section1');
  // const headingRefs = useRef<{ [key: string]: HTMLHeadingElement | null }>({});
  const { activeLanguage } = useVoerkaI18n();
  const [messageApi, contextHolder] = message.useMessage();

  const path =
    activeLanguage === "zh" ? "/static/doc/fqa.md" : "/static/doc/fqa_en.md";
  const auth = useAuth(); //全局登录态
  const [testToken, setTestToken] = useState('')
  let [searchParams] = useSearchParams();
  const paymentIntentId = searchParams.get("payment_intent");

  useEffect(() => {
    window.scrollTo(0, 0);
    if(paymentIntentId) {
      const confirmPayment = async () => {
        try {
          const response = await apiConfirmB2bPayment(paymentIntentId)
          if (response.data.code === 200) {
            searchParams.set("payment_intent", "");
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            ); // 清空传递参数
            // setTimeout(() => {
            //   message.success(response.data.message);
            // }, 1000);
          } else {
            setTimeout(confirmPayment, 1000); // 如果状态码不是 200，则继续请求
          }
        } catch (error) {
          console.error("请求出错：", error);
          searchParams.set("payment_intent", "");
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          ); // 清空传递参数
        }
      };
  
      confirmPayment();
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    // fetch(path)
    //   .then((response) => response.text())
    //   .then((text) => {
    //     const html = marked(text).toString();
    //     setMarkdown(html);

    //     const $ = cheerio.load(html);
    //     const h2Headings = $("h3")
    //       .map((_, element) => $(element).text())
    //       .get();
    //     setHeadings(h2Headings);
    //   })
    //   .catch((error) =>
    //     console.error("Error fetching the Markdown file:", error)
    //   );
  }, [id]);

  const [secretInfo, setSecretInfo] = useState({
    b2b_remaining_pages: '',
    client_secret: '',
    test_remaining_pdfs:''
  })
  useEffect(() => {
    if(auth.isLoggedIn) {
      apiGetTestKeySecret().then(res => {
        setTestToken(res.data.test_token)
      })
      apiGetKeySecret()
        .then(res => {
          setHasBuySecret(true)
          setSecretInfo({
            b2b_remaining_pages: res.data.b2b_remaining_pages,
            client_secret: res.data.client_secret,
            test_remaining_pdfs: res.data.test_remaining_pdfs
          })
        })
        .catch(err => {
          if(err.response.status === 404) {
            setHasBuySecret(false)
          }
        })
    }
  },[auth.isLoggedIn])

  // const handleScroll = () => {
  //   const elements = document.querySelectorAll("h3");
  //   let currentActiveHeading: string | null = null;

  //   elements.forEach((element) => {
  //     const rect = element.getBoundingClientRect();
  //     if (rect.top <= 128) {
  //       // 调整此值，88px为header高度，20px为sidebar的top偏移
  //       currentActiveHeading = element.textContent || null;
  //     }
  //   });

  //   setActiveHeading(currentActiveHeading);
  // };

  type SectionRefs = {
    [key: string]: React.RefObject<HTMLDivElement>;
  };
  const sectionRefs:SectionRefs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
  };

  const handleClick = (section: string) => {
    // const element = headingRefs.current[heading];
    // if (element) {
    //   window.scrollTo({
    //     top: element.offsetTop - 108, // 减去 header 的高度
    //     behavior: "smooth",
    //   });
    // }
    const element = sectionRefs[section].current;
    setActiveHeading(section)
    if(element) {
      window.scrollTo({
        top: element.offsetTop - 108, // 减去 header 的高度
        behavior: "smooth",
      })
    }
  };

  const triggerLogin = () => emitter.emit('login');
  const [hasBuySecret, setHasBuySecret] = useState(false)
  
const step1 = `#- **${t("端点")}**: GET /test-client-token
#- **URL**: https://niannianka.leqi.co/test-client-token
#- **${t("认证")}**: ${t("需要'web'类型的JWT Token")}
#- **${t("请求头")}**:
#- Authorization: 'Bearer <YOUR_TOKEN>'
#- **${t("响应")}**:
#- ${t("成功")} (200 OK): '{"test_token": "<CLIENT_SECRET>"}'
#- ${t("失败")} (404 Not Found): '{"message": "Please bind the e-mail first."}'
#
#**${t("Python代码示例")}**:
import requests

def get_client_secret(token):
    url = "https://niannianka.leqi.co/test-client-token"
    headers = {"Authorization": f"Bearer {token}"}
    response = requests.get(url, headers=headers)
    return response.json()

# ${t("使用示例")}
token = "<YOUR_WEB_JWT_TOKEN>"
print(get_client_secret(token))
`

const step2 = `#- **${t("端点")}**: POST /test-api/oauth/token
#- **URL**: https://niannianka.leqi.co/test-api/oauth/token
#- **${t("参数")}**: JSON payload with <client_secret>
#- **${t("请求头")}**:
#  - Content-Type: 'application/json'
#- **${t("响应")}**:
#  - ${t("成功")} (200 OK): "{"access_token": "<ACCESS_TOKEN>"}"
#  - ${t("失败")} (400 Bad Request): "{"msg": "Bad username or password"}"
#
#**${t("Python代码示例")}**:
import requests

def get_access_token(client_secret):
    url = "https://niannianka.leqi.co/test-api/oauth/token"
    payload = {"client_secret": client_secret}
    response = requests.post(url, json=payload)
    return response.json()

# ${t("使用示例")}
client_secret = "<YOUR_CLIENT_SECRET>"
print(get_access_token(client_secret))
`
const step3 = `#- **${t("端点")}**: POST /test-api/upload
#- **URL**: https://niannianka.leqi.co/test-api/upload
#- **${t("认证")}**: ${t("需要'test_company'类型的JWT Token")}
#- **${t("请求头")}**:
#  - Authorization: Bearer <YOUR_TOKEN>
#- **${t("参数")}**:
#  - "file": ${t("PDF文件，作为multipart/form-data上传")}
#- **${t("响应")}**:
#  - ${t("成功")} (200 OK): "{"filename": "<FILENAME>", "task_id": <TASK_ID>}"
#  - ${t("失败")} (400 Bad Request): "{"msg": "${t("错误信息，例如：No file part in the request")}"}"
#
#**${t("Python代码示例")}**:
import requests

def upload_pdf(token, file_path):
    url = "https://niannianka.leqi.co/test-api/upload"
    headers = {"Authorization": f"Bearer {token}"}
    files = {'file': open(file_path, 'rb')}
    response = requests.post(url, headers=headers, files=files)
    return response.json()

# ${t("使用示例")}
token = "<YOUR_TEST_COMPANY_JWT_TOKEN>"
file_path = "path/to/your/file.pdf"
print(upload_pdf(token, file_path))
`
const step4 = `#- **${t("端点")}**: GET /test-api/status
#- **URL**: https://niannianka.leqi.co/test-api/status
#- **${t("认证")}**: ${t("需要'test_company'类型的JWT Token")}
#- **${t("请求头")}**:
#  - Authorization: "Bearer <YOUR_TOKEN>"
#- **${t("参数")}**:
#  - task_id: ${t("PDF处理任务的ID")}
#- **${t("响应")}**:
#  - ${t("成功")} (200 OK): "{"task_id": "<TASK_ID>", "pdf_name": "<PDF_NAME>",
#  "status": "<STATUS>", "progress": "<PROGRESS>%"}"
#  - ${t("失败")} (500 Internal Server Error): "{"msg": "Unable to retrieve PDF tasks"}"
#
#**${t("Python代码示例")}**:
import requests

def get_pdf_status(token, task_id):
    url = f"https://niannianka.leqi.co/test-api/status?task_id={task_id}"
    headers = {"Authorization": f"Bearer {token}"}
    response = requests.get(url, headers=headers)
    return response.json()

# ${t("使用示例")}
token = "<YOUR_TEST_COMPANY_JWT_TOKEN>"
task_id = "<YOUR_TASK_ID>"
print(get_pdf_status(token, task_id))
`
const step5 = `#- **${t("端点")}**: GET /test-api/download
#- **URL**: https://niannianka.leqi.co/test-api/download
#- **${t("认证")}**: ${t("需要'test_company'类型的JWT Token")}
#- **${t("请求头")}**:
#  - Authorization: "Bearer <YOUR_TOKEN>"
#- **${t("参数")}**:
#  - task_id: ${t("PDF处理任务的ID")}
#- **${t("响应")}**:
#  - ${t("成功")} (200 OK): "{"compiled_pdf": "<DOWNLOAD_URL>"}"
#  - ${t("失败")} (404 Not Found): "{"msg": "PDF has not been completed"}"
#
#**${t("Python代码示例")}**:
import requests

def download_pdf(token, task_id):
    url = f"https://niannianka.leqi.co/test-api/download?task_id={task_id}"
    headers = {"Authorization": f"Bearer {token}"}
    response = requests.get(url, headers=headers)
    return response.json()

# ${t("使用示例")}
token = "<YOUR_TEST_COMPANY_JWT_TOKEN>"
task_id = "<YOUR_TASK_ID>"
print(download_pdf(token, task_id))
`
const [isPro, setPro] = useState(false);
const onProClick = async function (_isPro: boolean, pages: string) {
  setPro(_isPro);
  clickPayemnt(_isPro, pages);
};

const [clientSecret, setClientSecret] = useState("");
const clickPayemnt= (_isPro: boolean,pages: string) => {
  apiGetB2bPaymentDetail(pages, activeLanguage ?? "en")
    .then(res => {
      console.log(res.data);
      apiCreateB2bPaymentIntent({
        pages,
        pricing_code: res.data.data.pricing_code,
        payment_methods: res.data.data.payment_gateways
      }).then(res2 => {
        setClientSecret(res2.data.data.client_secret)
        setOpen(true)
      })
    })
}
const [open, setOpen] = useState(false); //model框
const handleCopyCode = (id: string) => {
  if(document) {
    const text = document.getElementById(id)?.innerHTML;
    navigator.clipboard.writeText(text || '').then(function() {
      messageApi.open({
        type: 'success',
        content: t("复制成功"),
      }); 
    }, function(err) {
        console.error('复制失败:', err);
    });
  }
}

const [companyName, setCompanyName] = useState('')
const [taxNumber, setTaxNumber] = useState('')
const [bankName, setBankName] = useState('')
const [phoneNumber, setPhoneNumber] = useState('')
const [address, setAddress] = useState('')
const [billSuccess, setBillSuccess] = useState(false)

const handleApplyBill = () => {
  // 检查输入框是否为空
  if (!companyName || !taxNumber || !bankName || !phoneNumber || !address) {
    messageApi.open({
      type: 'warning',
      content: t("请完善所有信息"),
    });
  } else {
    apiApplyBill({
      company_name: companyName,
      tax_number: taxNumber,
      bank_name: bankName,
      phone_number: phoneNumber,
      address
    }).then(res => {
      setCompanyName('')
      setTaxNumber('')
      setBankName('')
      setPhoneNumber('')
      setAddress('')
      setBillSuccess(true)
    })
    
  }
};

  // useEffect(() => {
  //   // window.addEventListener("scroll", handleScroll);
  //   // return () => {
  //   //   // window.removeEventListener("scroll", handleScroll);
  //   // };
  //   if(paymentId) {
  //     let timer = setInterval(() => {
  //       apiConfirmB2bPayment(paymentId).then((res) => {
  //         if (res.data.code === 200) {
  //           clearInterval(timer)
  //           setOpen(false);
  //         }
  //       })
  //     }, 3000)
  //   }
  // }, [paymentId]);

  return (
    <>
      {withMeta({
        title: t("API"),
        description: t(
          "API"
        ),
        keywords:
          "API",
      })}
      {contextHolder}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />
        <Main>
          <Title>API</Title>
          <Content>
            <Sidebar>
              <ContentTitle>
                <div className="title" style={{fontWeight:activeHeading === 'section1' ? '700' : '400'}} onClick={() => handleClick('section1')}>{t("正式密钥")}</div>
                <div className="title" style={{fontWeight:activeHeading === 'section2' ? '700' : '400'}} onClick={() => handleClick('section2')}>{t("测试环境")}</div>
                <div className="title" style={{fontWeight:activeHeading === 'section3' ? '700' : '400'}} onClick={() => handleClick('section3')}>{t("接入文档")}</div>
                <div className="title" style={{fontWeight:activeHeading === 'section4' ? '700' : '400'}} onClick={() => handleClick('section4')}>{t("申请发票")}</div>
              </ContentTitle>
            </Sidebar>
            <ContentMain>
              <div className="section" style={{marginTop: 0}} ref={sectionRefs.section1}>{t("正式密钥")}</div>
              {
                auth.isLoggedIn ? (
                  <>
                    {!hasBuySecret && <button className="asd" onClick={() => onProClick(false, '10000')}>{t("获取密钥")}</button> }
                    { hasBuySecret &&
                      <div className="paid-secret">
                        <span className="secret-box">
                          <span style={{fontWeight: 'bold'}}>{t("密钥:")}</span>
                          <span id="paid-secret">{secretInfo.client_secret}</span>
                          <span className="copy-btn" onClick={()=>handleCopyCode('paid-secret')}>{t("复制")}</span>
                        </span>
                        <button className="asd" onClick={() => onProClick(false, '10000')}>{t("充值")}</button>
                        <div className="remaining">{t("剩余：")}{secretInfo.b2b_remaining_pages}{t("页")}</div>
                      </div>
                    }
                  </>
                ) : (
                  <button className="asd" onClick={triggerLogin}>{t("登录获取密钥")}</button> 
                )
              }
              <div style={{display: 'flex', alignItems: 'end'}}>
                <div className="section" ref={sectionRefs.section2}>{t("测试环境")}</div>
                <span className="free-test">{t("可免费测试3次PDF文件")}</span>
              </div>
              {
                auth.isLoggedIn ? (
                  <div className="paid-secret">
                    <span className="secret-box">
                      <span style={{fontWeight: 'bold'}}>{t("密钥:")}</span>
                      <span id="test-secret">{testToken}</span>
                      <span className="copy-btn" onClick={()=>handleCopyCode('test-secret')}>{t("复制")}</span>
                    </span>
                    {hasBuySecret && <div className="remaining">{t("剩余：")}{secretInfo.test_remaining_pdfs}{t("页")}</div>}
                  </div>
                ) : (
                  <button className="asd" onClick={triggerLogin}>{t("登录获取密钥")}</button> 
                )
              }
              <div className="section" ref={sectionRefs.section3}>{t("接入文档")}</div>
              <div className="code-section">
                <div className="step">
                  {t("1. 获取客户端密钥")}
                  <span className="copy-btn" onClick={()=>handleCopyCode('step1')}>{t("复制代码")}</span>
                </div>
                <pre id="step1" className="step-code">{step1}</pre>
                <div className="step">
                  {t("2. 获取访问令牌")}
                  <span className="copy-btn" onClick={()=>handleCopyCode('step2')}>{t("复制代码")}</span>
                </div>
                <pre id="step2" className="step-code">{step2}</pre>
                <div className="step">
                  {t("3. 上传PDF文件")}
                  <span className="copy-btn" onClick={()=>handleCopyCode('step3')}>{t("复制代码")}</span>
                </div>
                <pre id="step3" className="step-code">{step3}</pre>
                <div className="step">
                  {t("4. 获取PDF处理状态")}
                  <span className="copy-btn" onClick={()=>handleCopyCode('step4')}>{t("复制代码")}</span>
                </div>
                <pre id="step4" className="step-code">{step4}</pre>
                <div className="step">
                  {t("5. 下载PDF文件")}
                  <span className="copy-btn" onClick={()=>handleCopyCode('step5')}>{t("复制代码")}</span>
                </div>
                <pre id="step5" className="step-code">{step5}</pre>
                {t("请确保将 `<YOUR_WEB_JWT_TOKEN>`, `<YOUR_TEST_COMPANY_JWT_TOKEN>`,")}<br/>
                {t("`<YOUR_CLIENT_SECRET>`, `<YOUR_TASK_ID>`, 和 `path/to/your/file.pdf` 替换为合适的值，以进行测试。")}
              </div>
              <div className="section" ref={sectionRefs.section4}>{t("申请发票")}</div>
              <Flex align="center" gap={isMobile() ? 0 : 20} wrap>
                <div className="bill-box">
                  <div><span style={{color: 'red'}}>*</span>{t("企业抬头:")}</div>
                  <input 
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder={t("请输入")}/>
                </div>
                <div className="bill-box">
                  <div><span style={{color: 'red'}}>*</span>{t("税号:")}</div>
                  <input
                    type="text"
                    value={taxNumber}
                    onChange={(e) => setTaxNumber(e.target.value)}
                    placeholder={t("请输入")}/>
                </div>
              </Flex>
              <Flex align="center" gap={isMobile() ? 0 : 20} wrap>
                <div className="bill-box">
                  <div><span style={{color: 'red'}}>*</span>{t("开户行:")}</div>
                  <input
                    type="text"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    placeholder={t("请输入")}/>
                </div>
                <div className="bill-box">
                  <div><span style={{color: 'red'}}>*</span>{t("电话:")}</div>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder={t("请输入")}/>
                </div>
              </Flex>
              <div className="bill-box">
                <div><span style={{color: 'red'}}>*</span>{t("地址:")}</div>
                <input
                  style={{width: isMobile() ? '100%' :px2vw(840)}}
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder={t("请输入")}/>
              </div>
              <button className="asd" onClick={handleApplyBill}>{t("申请")}</button>
            </ContentMain>
          </Content>
        </Main>
        <HomeFooter />
      </Layout>
      <Modal
        width={600}
        title={t("支付下载")}
        style={{
          fontSize: 28,
        }}
        centered
        open={open}
        footer={null}
        onOk={() => setOpen(false)}
        onCancel={() => {
          setPro(true);
          setOpen(false);
        }}
      >
        <center>
          <Space size={30}>
            <ModalTopButton onClick={() => onProClick(false, '10000')} isPro={!isPro}>
              <TBContent>
                <TopButtonLabel>$500</TopButtonLabel>
                <TopButtonLabel><span>{t("10000页")}</span></TopButtonLabel>
              </TBContent>
              {!isPro && <img className="sel" src={pay_sel} alt="" />}
            </ModalTopButton>
            <ModalTopButton onClick={() => onProClick(true, '50000')} isPro={isPro}>
              <TopIcon>{t("推荐")}</TopIcon>
              <TBContent>
                <TopButtonLabel>$2255</TopButtonLabel>
                <TopButtonLabel><span>{t("50000页")}</span></TopButtonLabel>
              </TBContent>
              {isPro && <img className="sel" src={pay_sel} alt="" />}
            </ModalTopButton>
          </Space>
        </center>
        {clientSecret ? (
          <center>
            <Elements
              options={{
                clientSecret: clientSecret,
                appearance: { theme: "stripe" },
              }}
              stripe={stripePromise}
            >
              <ApiCheckoutForm
                update={() => {
                  setOpen(false);
                }}
                clientSecret={clientSecret}
              />
            </Elements>
          </center>
        ) : (
          <div></div>
        )}
      </Modal>
      <Modal
        width={300}
        style={{
          fontSize: 28,
        }}
        centered
        open={billSuccess}
        footer={null}
        onOk={() => setBillSuccess(false)}
        onCancel={() => {
          setBillSuccess(false);
        }}>
          <BillSuccessBox>
            <img src={con_icon_secceed} alt="" />
            <span>{t("提交成功")}</span>
            {t("感谢您的提交，我们将在10个工作日内处理您的开票请求，如有疑问或其他要求请联系我们的邮箱：pdftopdf@leqi.ai")}
          </BillSuccessBox>
        </Modal>
    </>
  );
}

const Main = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  width: 1520px;
  height: auto;
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    margin-top: 0px;
    padding: 102px;
  }
`;

const Line = styled.div`
  width: 1400px;
  height: 1px;
  background: #ebebeb;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Title = styled.label`
  width: 1320px;
  height: auto;
  font-family: Montserrat Bold;
  font-weight: bold;
  font-size: 40px;
  color: #222222;
  line-height: 49px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 90%;
    font-size: 123px;
    line-height: 148px;
  }
`;

const Content = styled.div`
  width: 100%;
  /* font-family: Montserrat Light; */
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 36px;
  text-align: left;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 77px;
    line-height: 97px;
  }
`;

const KeyWordsFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 50px;

  @media only screen and (max-width: 768px) {
    gap: 40px;
    margin-top: 100px;
  }
`;

const KeyWords = styled.label`
  padding: 4px 8px;
  width: auto;
  height: 40px;
  border-radius: 8px;
  border: 2px solid #373737;
  font-weight: 400;
  font-size: 20px;
  color: #373737;
  line-height: 28px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 57px;
    line-height: 77px;
    height: auto;
    border-radius: 20px;
    border: 5px solid #373737;
    padding: 16px;
  }
`;

const ContentTitle = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  .title {
    margin-bottom: 30px;
    font-size: 20px;
    font-family: Montserrat Bold;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
    font-size: ${mToPcPx(15)};
    /* line-height: 97px; */
    margin-bottom: ${mToPcPx(16)};
    color: #0d70e6;
  }
  }
`;

const ContentItem = styled.li<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  font-size: 24px;
  color: #222222;
  line-height: 29px;
  margin-bottom: 30px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 97px;
    margin-bottom: 82px;
    color: #0d70e6;
  }
`;

const ContentMain = styled.div`
  flex: 1;
  padding-left: 40px;
  @media only screen and (max-width: 768px) {
    padding-left: 0px;
  }
  .section {
    margin-top: 80px;
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: bold;
    @media only screen and (max-width: 768px) {
      margin-top: ${mToPcPx(40)};
      font-size: ${mToPcPx(16)};
    }
  }

  .asd {
    margin: 20px 0 40px;
    width: 280px;
    height: 60px;
    color: #222;
    font-size: 20px;
    font-weight: bold;
    background: #FDC448;
    box-shadow: 6px 6px 0px 0px #373737;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: ${mToPcPx(48)};
      margin-top: ${mToPcPx(10)};
      font-size: ${mToPcPx(16)};
      box-shadow: ${mToPcPx(6)} ${mToPcPx(6)} 0 0 #373737;
      border-radius: ${mToPcPx(8)};
    }
  }
  .free-test {
    margin-left: 12px;
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      margin-left: ${mToPcPx(4)};
      font-size: ${mToPcPx(12)};
    }
  }
  .paid-secret {
    display: flex;
    align-items: center;
    button {
      margin: 0 0 0 20px;
      width: 90px;
      @media only screen and (max-width: 768px) {
        margin: ${mToPcPx(8)} 0 ${mToPcPx(16)};
        width: 100%;
      }
    }
    .remaining {
        margin-left: 20px;
    }
    @media only screen and (max-width: 768px) {
      display: block;
      .remaining {
        margin-top: ${mToPcPx(10)};
        text-align: center;
      }
    }
  }
  .secret-box {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    font-size: 18px !important;
    background: #F8FAFF;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    @media only screen and (max-width: 768px) {
      width: 100%;
      flex-wrap: wrap;
      margin-top: ${mToPcPx(10)};
      padding:  ${mToPcPx(14)} ${mToPcPx(16)};
      font-size: ${mToPcPx(14)} !important;
      border-radius: ${mToPcPx(8)};
      border: ${mToPcPx(1)} solid #EBEBEB;
      #test-secret, #paid-secret {
        word-wrap: break-word; /* 长单词或 URL 会换行 */
        overflow-wrap: break-word; /* 支持更广泛的浏览器 */
        white-space: normal; /* 允许文本换行 */
        max-width: 100%; /* 确保元素不会超出容器的宽度 */
      }
    }
  }
  .copy-btn {
    padding: 4px 12px;
    color: #222;
    background: #FFFFFF;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      padding:  ${mToPcPx(4)} ${mToPcPx(8)};
      font-size: ${mToPcPx(14)} !important;
      border-radius: ${mToPcPx(8)};
      border: ${mToPcPx(1)} solid #EBEBEB;
    }
  }
  .code-section {
    margin-top: 20px;
    padding: 30px;
    font-size: 18px;
    background: #F8FAFF;
    border-radius: 12px;
    border: 1px solid #EBEBEB;
    @media only screen and (max-width: 768px) {
      margin-top: ${mToPcPx(20)};
      padding:  ${mToPcPx(10)};
      font-size: ${mToPcPx(14)} !important;
      border-radius: ${mToPcPx(12)};
      border: ${mToPcPx(1)} solid #EBEBEB;
    }
  }
  .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #04B42A;
    @media only screen and (max-width: 768px) {
      font-size: ${mToPcPx(14)} !important;
    }
  }
  .step-code {
    font-size: 18px;
    @media only screen and (max-width: 768px) {
      /* white-space: wrap; */
      overflow: scroll;
      font-size: ${mToPcPx(14)} !important;
    }
  }
  .bill-box {
    font-size: 18px !important;
    @media only screen and (max-width: 768px) {
      margin-top: ${mToPcPx(20)};
      width: 100%;
      font-size: ${mToPcPx(14)} !important;
    }
    input {
      margin-bottom: 30px;
      width: 400px;
      padding: 16px 0 16px 20px;
      background: #F8FAFF;
      font-size: 18px;
      border-radius: 8px;
      border: 1px solid #EBEBEB;
      outline: none;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: ${mToPcPx(14)} ${mToPcPx(8)};
        font-size: ${mToPcPx(14)} !important;
        border-radius: ${mToPcPx(8)};
        border: ${mToPcPx(1)} solid #EBEBEB;
      }
    }
  }
`;

const Sidebar = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (min-width: 769px) {
    width: 100%;
    position: sticky;
    top: 108px; /* 88px header + 20px margin */
    width: 240px;
    height: fit-content;
    align-self: flex-start; /* 确保 Sidebar 从顶部开始 */
  }
`;

const ModalTopButton = styled.button<{ isPro: boolean }>`
  position: relative;
  width: 255px;
  /* height: 70px; */
  padding: 20px 0;
  background: #ffffff;
  border-radius: 8px;
  border: 3px solid ${({ isPro }) => (isPro ? "#222222" : "#EBEBEB")};
  margin-top: 12px;
  margin-bottom: 12px;

  color: ${({ isPro }) => (isPro ? "#222222" : "#999999")};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  * {
    color: inherit;
  }

  @media only screen and (max-width: 768px) {
    width: 768px;
    /* height: 205px; */
    border-radius: 20px;
  }
  .sel {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    @media only screen and (max-width: 768px) {
      width: ${mToPcPx(24)};
      height: ${mToPcPx(24)};
    }
  }
`;

const TBContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TopButtonLabel = styled.label`
  width: auto;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  margin-right: 16px;
  span {
    font-size: 20px;
    @media only screen and (max-width: 768px) {
      font-size: ${mToPcPx(12)};
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: ${mToPcPx(16)};
    line-height: ${mToPcPx(16)};
  }
`;

const TopButtonLabelRight = styled.label`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 62px;
    line-height: 92px;
  }
`;

const TopButtonLabelRB = styled.label`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    line-height: 92px;
  }
`;

const TopIcon = styled.label`
  position: absolute;
  left: -3px;
  top: -16px;
  padding: 0px 5px 0px;
  height: 32px;
  background: linear-gradient(180deg, #f49f9f 0%, #f53e3e 100%);
  border-radius: 24px 100px 100px 0px;

  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  font-style: normal;

   @media only screen and (max-width: 768px) {
    height: 82px;
    font-size: 46px;
    line-height: 82px;
    padding: 0px 25px 0px;
     top: -36px;
   }
`;

const PayButton = styled.button`
  cursor: pointer;
  margin-top: 24px;
  background: #fdc448;
  width: 300px;
  height: 60px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    width: 768px;
    height: 164px;
    margin-top: 48px;
    background: #fdc448;
    box-shadow: 15px 15 0px 0px #373737;
    border-radius: 20px;

    font-size: 72px;
    line-height: 92px;
  }
`;

const BillSuccessBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
  font-size: 20px;
  text-align: center;
  @media only screen and (max-width: 768px) {
    padding: ${mToPcPx(40)} ${mToPcPx(20)};
    font-size: ${mToPcPx(12)};
  }
  span {
    margin: 16px 0;
    font-size: 24px;
    font-weight: bold;
    @media only screen and (max-width: 768px) {
      margin: ${mToPcPx(6)} 0;
      font-size: ${mToPcPx(16)};
    }
  }
  img {
    width: 80px;
    @media only screen and (max-width: 768px) {
      width: ${mToPcPx(60)};
    }
  }
`