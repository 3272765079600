import React from "react";
import styled from "styled-components";
import home_icon_check from "../../../assets/home/home_fe_sel@2x.png";

const Container = styled.div`
  position: relative;
  display: inline-block;

  @media only screen and (max-width: 768px) {
    width: 1331px;
  }
`;

const BackgroundBox = styled.div<{
  top?: number;
  left?: number;
  opacity?: number;
}>`
  background-color: #ffffff;
  position: absolute;
  top: ${(props) => (props.top ? props.top : 0)}px;
  left: ${(props) => (props.left ? props.left : 0)}px;
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  width: 100%;
  height: 100%;
  pointer-events: none; /* 避免影响前景内容的点击事件 */
  border-radius: 6px;
  border: 3px solid #222222;
`;

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  
  color: white;
  border-radius: 6px;
  border: 3px solid #222222;
  white-space: nowrap;
  position: relative;
  z-index: 1;
`;

const TitileImage = styled.img`
  @media only screen and (max-width: 768px) {
    width: 143px;
    height: 143px;
    padding: 20px;
  }

  @media only screen and (min-width: 769px) {
    width: 64px;
    height: 64px;
    padding: 10px;
  }
`;

const TitleLabel = styled.label`
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  line-height: 54px;
  text-align: center;
  font-style: normal;
  margin-left: 16px;
  margin-right: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 97px;
  }
`;

const OverlappingTitleBoxes: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Container>
      <BackgroundBox top={8} left={5} opacity={0.5} />
      <ContentBox>
        <TitileImage src={home_icon_check} />
        <div style={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
          <TitleLabel>{label}</TitleLabel>
        </div>
      </ContentBox>
    </Container>
  );
};

export default OverlappingTitleBoxes;
