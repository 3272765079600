export const test_array = [
  {
    seo_id: 1,
    name: '',
    title: "Optimizing AI Learning Materials: pdftopdf.ai's Format Preservation Technology",
    content:
      "Incorporating Every Detail into Learning: Advanced OCR Technology ApplicationWhen building large AI language models, understanding the context of the text is crucial. Traditional OCR technology, while capable of recognizing text content, often overlooks formatting such as bold text, titles, and lists, which are key clues for understanding the structure and emphasis of the text. pdftopdf.ai does more than just recognize text; it precisely restores the original format of documents, ensuring that AI models can obtain complete document structure information for deeper understanding and learning.Format and Content: A Dual ApproachOur technology not only accurately recognizes and transcribes text but also captures every detail of the formatting, such as bold fonts, italics, or underlining, all of which play a crucial role in the training process of AI. These technological advantages make pdftopdf.ai an ideal choice for AI developers and data scientists training language models. By providing richer, more structured learning materials, AI models' learning efficiency and depth of understanding are significantly enhanced.Why Choose pdftopdf.ai?- Comprehensive Text and Format Capture: We ensure no detail is missed during the conversion process, providing complete learning materials for AI models.- Accelerating AI Training and Research: By offering more accurate and comprehensive data, we help researchers and developers shorten the AI model training cycle and improve the models' performance.",
  },
  {
    seo_id: 2,
    name: '',
    title: 'Bridging Languages: pdftopdf.ai Makes Reading Boundless',
    content:
      "From Scanning to Global Sharing: Your Book Journey BeginsImagine holding a precious book in a foreign language that you can't read. With pdftopdf.ai, this book can be easily converted into a complete, accurate digital format, eliminating your concerns about language barriers. Our OCR technology not only recognizes text but also preserves the original layout of each page, making subsequent translation or learning much easier.Bringing Books to EveryoneWhether you are a student, a teacher, or simply someone who loves books, pdftopdf.ai can help you overcome language barriers and enjoy the pleasure of reading. Once the book is converted into a digital document, you can use any translation tool to translate it into your native language or share it with readers worldwide. Isn't this possibility exciting?Reasons to Choose pdftopdf.ai- Ease of Use: Just a few simple steps can convert your paper books into high-quality digital versions.- Sharing and Learning: The converted documents are easy to share and translate, making the transfer of knowledge more free and making learning without borders.These translations reflect the content and intent of the original articles, maintaining a professional yet accessible tone suitable for an international audience.",
  },
  {
    seo_id: 3,
    name: '',
    title: '优化AI学习材料：pdftopdf.ai的格式保留技术',
    content:
      '让每个细节都计入学习：OCR技术的进阶应用在构建AI大语言模型时，了解文本的上下文是至关重要的。传统的OCR技术虽然能识别文本内容，但往往忽略了格式，如加粗、标题和列表等元素，这些都是理解文本结构和重点的关键线索。pdftopdf.ai不仅仅是识别文字，更通过精确的格式还原，确保AI模型能够获取完整的文档结构信息，从而更深入地理解和学习文本内容。格式与内容：双管齐下我们的技术不仅可以精确地识别和转录文字，还能捕捉到每一处格式的细节，如加粗字体、斜体或下划线，这些都在AI的训练过程中起到了极其重要的作用。这样的技术优势让pdftopdf.ai成为AI开发者和数据科学家在训练语言模型时的理想选择。通过提供更丰富、结构化的学习材料，AI模型的学习效率和理解深度得到显著提升。为何选择pdftopdf.ai？- 全面的文本和格式捕捉：我们保证转换过程中无细节遗漏，为AI模型提供完整的学习资料。- 加速AI训练和研究：通过提供更加准确和全面的数据，帮助研究人员和开发者缩短AI模型的训练周期，提高模型的表现。',
  },
  {
    seo_id: 4,
    name: '',
    title: '跨越语言的桥梁：pdftopdf.ai让阅读无界限',
    content:
      '从扫描到全球分享：您的书籍旅程开始了想象一下，您手中有一本珍贵的外文书籍，却因为语言的障碍无法阅读。有了pdftopdf.ai，这本书可以轻松转换成一个结构完整、内容准确的电子格式，您再也不用担心语言问题了。我们的OCR技术不仅识别文字，更保留了每一页的原始布局，让后续的翻译或学习变得更加容易。把书籍带给每一个人无论您是学生、教师还是仅仅是一个爱书的人，pdftopdf.ai都可以帮助您超越语言的障碍，享受阅读的乐趣。将书籍转换成电子文档后，您可以选择使用任何翻译工具将其翻译成您的母语，或者分享给全世界的读者。这种可能性，是不是让您感到无比兴奋呢？选择pdftopdf.ai的理由- 易于使用：几个简单的步骤，就可以将您的纸质书籍转换为高质量的电子版本。- 分享与学习：转换后的文档易于分享和翻译，让知识的传递更自由，让学习无国界。',
  },
]

export const isMobile = () => {
  return window.innerWidth <= 768
}

//px 转vw
export function px2vw(px: any) {
  return `${(px / 1920) * 100}vw`
}

// px转vw返回number
export function px2vw_num(px: any) {
  return (px / 1920) * 100
}

//获取当前缩放实际像素
export function calculateLayoutWidth(px: any) {
  const originalWidth = 1920
  const viewportWidth = window.innerWidth
  return (px / originalWidth) * viewportWidth
}

interface ContryMaps {
  [key: string]: string
}

const contryMaps: ContryMaps = {
  CN: 'zh',
}

export function ct2Language(contry: string) {
  if (contryMaps.hasOwnProperty(contry)) {
    return contryMaps[contry]
  } else {
    return 'en'
  }
}

// 定义一个高阶函数，它接收一个函数并返回一个新的函数
export function withTryCatch<T extends any[], R>(func: (...args: T) => R): (...args: T) => R | undefined {
  return (...args: T): R | undefined => {
    try {
      // 尝试执行原始函数
      return func(...args)
    } catch (error) {
      // 捕获并处理错误
      console.error('Error occurred:', error)
      // 可以在这里决定如何处理错误，比如返回undefined或特定的错误对象
      return undefined
    }
  }
}

export function mToPcPx(num: any) {
  return `${(num * 5.12 / 1920) * 100}vw`
}
