import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./style.css";
import {
  PaymentInfo,
  apiUsePromoCode,
  apiValidatePageConsumed
} from "../../libs/http/api";
import styled from "styled-components";
import { t } from "../../languages";
import { Flex, Spin, message } from "antd";
import { px2vw } from "../../libs/utils";
import { useRequest } from "ahooks";
import { useVoerkaI18n } from "@voerkai18n/react";
import { useAuth } from "../../components/AuthContext";
import { logEvent } from "../../libs/analytics";

interface PaymentInfoProps {
  pdf_id?: number;
  data?: PaymentInfo;
  clientSecret: string;
  update(): void;
}

function useApi() {
  const promocode = useRequest(apiUsePromoCode, { manual: true }); // 使用邀请码
  const { runAsync: useCode } = promocode;

  const vpc = useRequest(apiValidatePageConsumed, { manual: true }); // 消耗剩余次数
  const { runAsync: useCount } = vpc;

  return { useCode, useCount };
}

export default function CheckoutForm(porps: PaymentInfoProps) {
  const { activeLanguage } = useVoerkaI18n();
  const [code, setCode] = useState<string>("");
  const api = useApi();
  const auth = useAuth();

  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false); //model框
  const [spinning, setSpinning] = React.useState<boolean>(false); // 全屏加载框
  const [messageTv, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWait, setIsWait] = useState(true);


  setTimeout(() => {
    setIsWait(false);
  }, 2000);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!porps.clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(porps.clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  //支付表单提交
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // if (porps.data?.is_remaining_pages) {
    //   // 判断是否需要消耗次数
    //   setSpinning(true);
    //   return;
    // }

    if (!stripe || !elements || isWait) {
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      // redirect: 'if_required',
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message ?? "");
    } else {
      setMessage("An unexpected error occurred.");
    }
    // logEvent("Home", "payment_sumbit", porps.data?.total_price.toString());
    setIsLoading(false);
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement
          id="payment-element"
          options={{
            layout: {
              type: "tabs",
            },
          }}
        />
        <Flex
          style={{ marginTop: 24 }}
          vertical
          justify="center"
          align="center"
        >
          <PayButton
            disabled={isLoading || !stripe || !elements || isWait}
            id="submit"
          >
            <span id="button-text">{isLoading ? <Spin /> : t("支付")}</span>
          </PayButton>
        </Flex>
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">{messageTv}</div>} */}
      </form>
      <Spin spinning={spinning} fullscreen />
    </>
  );
}

const PageCountTv = styled.text`
  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #ff8152;
  line-height: 27px;
  text-align: left;
  font-style: normal;
  margin-top: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 61px;
    line-height: 77px;
  }
`;

const PriceTv = styled.text`
  font-family: SourceHanSansCN;
  font-weight: bold;
  font-size: 36px;
  color: #222222;
  line-height: 54px;
  text-align: left;
  font-style: normal;
  margin-top: 12px;
  @media only screen and (max-width: 768px) {
    font-size: 92px;
    line-height: 138px;
  }
`;

const PayButton = styled.button`
  cursor: pointer;
  margin-top: 24px;
  background: #fdc448;
  width: 300px;
  height: 60px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  margin-bottom: 16px;

  @media only screen and (max-width: 768px) {
    width: 768px;
    height: 164px;
    margin-top: 48px;
    background: #fdc448;
    box-shadow: 15px 15 0px 0px #373737;
    border-radius: 20px;

    font-size: 72px;
    line-height: 92px;
  }
`;

const InvCodeTv = styled.div`
  height: 29px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #666666;
  line-height: 29px;
  text-align: left;
  font-style: normal;
  margin-top: 12px;

  @media only screen and (max-width: 768px) {
    font-size: 51px;
    line-height: 67px;
  }
  /* text-decoration-line: underline;
    cursor: pointer; */
`;

const InvTitleTv = styled.div`
  width: 100%;
  font-family: SourceHanSansCN;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  line-height: 29px;
  text-align: left;
  font-style: normal;
`;

const InputCode = styled.input`
  margin-top: 50px;
  margin-bottom: 24px;
  width: 431px;
  background: #f5f6f7;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  outline: none;
  font-size: 24px;
  padding: 21px;

  line-height: 36px;
  font-weight: 400;
`;
