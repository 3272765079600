import { v4 } from "uuid";

export default function deviceuuid() {
  const uuid = localStorage.getItem("deviceuuid");

  if (uuid) return uuid;

  const newUuid = v4();
  localStorage.setItem("deviceuuid", newUuid);
  return newUuid;
}
