import { useState, useEffect } from 'react';
import { isMobile } from '../libs/utils';


const useIsMobile = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(isMobile());
    };

    window.addEventListener('resize', handleResize);

    // 清除事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileDevice;
};

export default useIsMobile;
