import React, { useEffect } from "react";
import styled from "styled-components";

import { isMobile } from "../../../libs/utils";
import { Link, useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import { apiArticleList } from "../../../libs/http/api";
import { useVoerkaI18n } from "@voerkai18n/react";

const BlogBox: React.FC = () => {


  const articleList = useRequest(apiArticleList, { manual: true }); // 获取文章列表
  const { runAsync: articleGet } = articleList;

  const { activeLanguage } = useVoerkaI18n();
  const navigate = useNavigate();

  useEffect(() => {
    articleGet(activeLanguage ?? "en")
      .then((resp) => {
        console.log(resp);
      })
      .catch();
  }, [activeLanguage]);

  return (
    <WWTSContainer>
      {isMobile() ? <div></div> : null}
      {articleList?.data?.data.data?.slice(0, 3).map((item) => (
        <Link key={item.seo_id} to={`/article/${item.index}/${item.keyword_tag}`}>
          <SayItemStyle>
            <SayImage src={item.image_path} alt="" />
            <span style={{}}>{item.title}</span>
          </SayItemStyle>
        </Link>
      ))}
    </WWTSContainer>
  );
};

export default BlogBox;

const WWTSContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: auto; /* 允许容器内容滚动 */
  gap: 50px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    width: 100%;
    gap: 102px;
  }
`;

const SayItemStyle = styled.div`
  width: 474px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  span {
    margin-top: 24px;
    font-weight: 400;
    font-size: 28px;
    color: #222222;
    line-height: 41px;
    text-align: left;
    font-style: normal;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* 定义文本的行数 */
    -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 超出文本显示省略号 */
    white-space: normal; /* 正常换行 */
    max-height: 3em; /* 限制最大高度，根据行高调整 */
    line-height: 1.5em;
  }

  @media only screen and (max-width: 768px) {
    width: 1213px;

    span {
      margin-top: 61px;
      font-weight: 400;
      font-size: 77px;
      color: #222222;
      line-height: 97px;
      text-align: left;
      font-style: normal;

      display: -webkit-box;
      -webkit-line-clamp: 2; /* 定义文本的行数 */
      -webkit-box-orient: vertical; /* 设置布局方向为垂直 */
      overflow: hidden; /* 隐藏超出容器的内容 */
      text-overflow: ellipsis; /* 超出文本显示省略号 */
      white-space: normal; /* 正常换行 */
      max-height: 3em; /* 限制最大高度，根据行高调整 */
      line-height: 1.5em;
    }
  }
`;

const SayImage = styled.img`
  width: 474px;
  height: 304px;
  object-fit: cover;

  border-radius: 16px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 778px;
    border-radius: 42px;
  }
`;
