import { Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import icon_upload from "../../../assets/historical/icon_upload@2x.png";
import { t } from "../../../languages";
import { px2vw } from "../../../libs/utils";
import useRequest from "ahooks/lib/useRequest";
import { apiGetFreeCount } from "../../../libs/http/api";
import home_free_box from "../../../assets/home/home_free_box@2x.png"

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#FFFFFF";
};


const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  pointer-events: none;
`;

interface DropCallback {
  onFile(file: File): void;
}

export default function StyledDropzone(drop: DropCallback) {
  const freeAttempt = useRequest(apiGetFreeCount, { manual: true })
 
  useEffect(()=>{
    freeAttempt.runAsync().then((resp)=>{
      if(resp.data){
        setCount(resp.data.data.count)
      }
    }).catch()
  },[])

  const [count,setCount] = useState(0)


  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "application/pdf": [] },
      onDrop(acceptedFiles, fileRejections, event) {
        console.log(acceptedFiles instanceof File);
        if (
          acceptedFiles[0] instanceof File &&
          acceptedFiles[0].name.endsWith(".pdf")
        ) {

          drop.onFile(acceptedFiles[0])
        } else {
          message.error(t("请上传正确的PDF文件！"));
        }
      },
    });

  function splitToFiveDigits(num: number): number[] {
    // 将数字转换成5位数的字符串，不足5位的前面补零
    const numStr = num.toString().padStart(5, '0');

    // 将字符串分割成字符数组并转换为数字数组
    return numStr.split('').map(Number);
  }

  return (
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <FreeBox>
        <TopText>{t("免费处理文件数")}</TopText>
        <Hbox>
          {
            splitToFiveDigits(count).map((number,index)=>(
              <CountBox>{number}</CountBox>
            ))
          }
        </Hbox>
        <UploadBox>
          <UploadIcon src={icon_upload} />
          <Text1>{t("上传文件")}</Text1>
          <Text2>{t("支持格式：PDF")}</Text2>
          <input {...getInputProps()} />
        </UploadBox>
      </FreeBox>
    </Container>
  );
}

const Hbox = styled.div`
  display: flex;
  gap: 3px;
  justify-content: center;
  margin-top: 18px;
`

const TopText = styled.label`
  margin-top: 16px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  font-style: normal;
`

const FreeBox = styled.div`
  width: 289px;
  height: 302px;
  background: #FDC448;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  background-color: #FF7480;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: url(${home_free_box}) top center;
`
const UploadBox = styled.div`
  margin-top: 12px;
  width: 280px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 24px;
  backdrop-filter: blur(21px);
  margin-bottom: 6px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`
const UploadIcon = styled.img`
  width: 56px;
  height: 56px;
`
const Text1 = styled.label`
  margin-top: 6px;
  font-size: 24px;
  color: #333333;
`
const Text2 = styled.label`
  margin-top: 6px;
  font-size: 18px;
  color: #333333;
`
const CountBox = styled.div`
  width: 36px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 4px;
  
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 31px;
  color: #FF5573;
  line-height: 46px;
  text-align: center;
  font-style: normal;
`