import Layout from "antd/es/layout/layout";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { apiArticleList } from "../../libs/http/api";
import { useRequest } from "ahooks";
import styled from "styled-components";
import { Flex } from "antd";
import { isMobile, px2vw } from "../../libs/utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useVoerkaI18n } from "@voerkai18n/react";
import { t } from "../../languages";
import { useParams } from "react-router-dom";
import map_detail_img_1 from "../../assets/scenes/map_detail_img_1@2x.png";
import map_detail_img_2 from "../../assets/scenes/map_detail_img_2@2x.png";
import map_detail_img_3 from "../../assets/scenes/map_detail_img_3@2x.png";
import map_detail_img_4 from "../../assets/scenes/map_detail_img_4@2x.png";
import map_detail_img_5 from "../../assets/scenes/map_detail_img_5@2x.png";
import map_detail_img_7 from "../../assets/scenes/map_detail_img_7@2x.png";
import map_detail_img_8 from "../../assets/scenes/map_detail_img_8@2x.png";
import map_detail_img_9 from "../../assets/scenes/map_detail_img_9@2x.png";
import map_detail_img_10 from "../../assets/scenes/map_detail_img_10@2x.png";
import map_detail_img_11 from "../../assets/scenes/map_detail_img_11@2x.png";
import map_detail_img_12 from "../../assets/scenes/map_detail_img_12@2x.png";
import map_detail_img_13 from "../../assets/scenes/map_detail_img_13@2x.png";
import map_detail_img_14 from "../../assets/scenes/map_detail_img_14@2x.png";
import { useUpload } from "../../components/FileUploadContext";
import React, { useRef } from "react";
import withMeta from "../../components/withMeta";
import { logEvent } from "../../libs/analytics";


export interface ContentItem {
  title: string;
  content: JSX.Element;
}

export interface ContentDictionary {
  [key: string]: ContentItem;
}

export default function PdfSmaller() {


  const { addFiles } = useUpload();
  const { id } = useParams();
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      logEvent("Home", "file_upload", "upload");
      addFiles(Array.from([file]));
      navigate("/historical");
    }
  };

  const contentDictionary: ContentDictionary = {
    "0": {
      title: t("图书转文字"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("减少95%存储成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("通过图片转文字进行存储")}</b> <br />
                {t("90MB轻松转为6MB")}
                <br />
                {t("瘦身文件简简单单")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_13} alt={t("图片转文字节省空间示例图")}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_14} alt={t("图片转文字存储更多示例图")}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("你可以存更多")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("存储更多，看见更多")}
                <br />
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
    }
  };

  return (
    <>
      {withMeta({
        title: t("上传PDF扫描件，轻松转换文本——高效PDF转文字工具"),
        description: t(
          "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。"
        ),
        keywords:
          "pdf to pdf conversion，pdf convert to word converter，ocr text recognition，image pdf to pdf converter，free online OCR，compress pdf online",
      })}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />
        <Content>
          <Title>{contentDictionary[id ?? "0"].title}</Title>
          {isMobile() ? <Line /> : null}
          {contentDictionary[id ?? "0"].content}

          {isMobile() ? (
            <UploadButton onClick={() => { fileInputRef.current?.click() }}>{t("上传文件")}</UploadButton>
          ) : (
            <MoreButton
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              {t("上传文件")}
            </MoreButton>
          )}
        </Content>
        <HomeFooter />
      </Layout>
      <input
      accept=".pdf"
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
}

const ContentFlex = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SizedBox = styled.div`
  width: 100px;
`;

const Line = styled.div`
  width: 1818px;
  height: 5px;
  background: #ebebeb;
  margin-top: 154px;
`;

const Content = styled.div`
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 60px;
  margin-bottom: 100px;
  width: 1520px;
  height: 1269px;
  background: #ffffff;
  border-radius: 24px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    height: 1269px;
    height: auto;
    margin-top: 0px;
    border-radius: 82px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled.div`
  margin-top: 60px;
  font-family: Montserrat Blod;
  font-weight: bold;
  font-size: 48px;
  color: #222222;
  line-height: 71px;
  text-align: center;
  font-style: normal;
  text-transform: none;

  @media only screen and (max-width: 768px) {
    font-size: 123px;
    line-height: 148px;
    margin-top: 154px;
  }
`;
const ContentTextBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: start;
    margin-top: 154px;
  }
`;

const ContentTextBoxEnd = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: center;
    margin-top: 154px;
  }
`;

const ContentTextBoxTitle = styled.div`
  font-family: Montserrat Blod;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: left;
  font-style: normal;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    font-size: 113px;
    line-height: 138px;
    white-space: nowrap;
  }
`;

const ContentTextBoxTitleEnd = styled.div`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: end;
  font-style: normal;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 113px;
    line-height: 138px;
    white-space: nowrap;
    text-align: start;
  }
`;

const ContentTextBoxTxt = styled.div`
  margin-top: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 123px;
    margin-top: 42px;
  }
`;

const ContentTextBoxTxtEnd = styled.div`
  margin-top: 24px;
  font-family: Montserrat Regular;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: end;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 77px;
    line-height: 123px;
    white-space: nowrap;
    text-align: start;
  }
`;

const ContentImage = styled.img<{ order?: number }>`
  width: 480px;
  height: 480px;

  @media only screen and (max-width: 768px) {
    width: 1229px;
    height: 1229px;
    order: ${(props) => (props.order ? props.order : 0)};
  }
`;

const MoreButton = styled.button`
  width: 420px;
  background: #fdc448;
  height: 68px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;

  position: fixed;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
`;

const UploadButton = styled.button`
  position: fixed;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 819px;
  background: #fdc448;
  height: 246px;
  font-size: 82px;
  box-shadow: 31px 31px 0px 0px #373737;
  border-radius: 41px;
  border: none;
  cursor: pointer;
  color: #333333;
`;