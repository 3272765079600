import { Flex, Layout } from "antd";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import icon_upload from "../../../assets/historical/icon_upload@2x.png";

import { message } from "antd";
import { t } from "../../../languages";
import { px2vw } from "../../../libs/utils";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "transparent";
};

interface DropCallback {
  onFile(file: File): void;
}

export default function StyledDropzone(drop: DropCallback) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "application/pdf": [] },
      onDrop(acceptedFiles, fileRejections, event) {
        console.log(acceptedFiles instanceof File);
        if (
          acceptedFiles[0] instanceof File &&
          acceptedFiles[0].name.endsWith(".pdf")
        ) {
          drop.onFile(acceptedFiles[0]);
          message.success(t("正在上传PDF文件..."));
        } else {
          message.error(t("请上传正确的PDF文件！"));
        }
      },
    });

  return (
    // <div className="container">

    // </div>
    <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <UploadStyle>
        <Flex>
          <UploadImage src={icon_upload} />
          <UploadTitle>{t("上传文件")}</UploadTitle>
        </Flex>
        <UploadContent>
          {t("文件上传完成后可以确认生成格式，您可以同时上传更多文件")}
        </UploadContent>
        {/* <input {...getInputProps()} /> */}
      </UploadStyle>
    </Container>
  );
}

const UploadStyle = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    height: 717px;
  }
`;

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 24px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: white;
  color: #bdbdbd;
  outline: none;
  transition: border 0.1s ease-in-out;
  height: 128px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 1818px;
    height: 717px;
    background: #ffffff;
    border-radius: 123px;
    border: 5px solid #ebebeb;
  }
`;

const UploadImage = styled.img`
  width: 40px;
  height: 40px;
  @media only screen and (max-width: 768px) {
    width: 123px;
    height: 123px;
  }
`;

const UploadTitle = styled.div`
  margin-left: 4px;
  color: #222222;
  font-size: 22px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 72px;
    margin-left: 20px;
  }
`;

const UploadContent = styled.div`
  margin-left: 9px;
  color: #999999;
  font-size: 18px;
  font-weight: bold;
  line-height: 29px;

  @media only screen and (max-width: 768px) {
    font-size: 61px;
    margin-left: 20px;
    line-height: 77px;
    padding-left: 108px;
    padding-right: 108px;
    margin-top: 51px;
  }
`;
