import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import { Flex, Layout } from "antd";

const stripePromise = loadStripe("pk_test_51Oxlc4P0gMU7zzVv3o4Zsrfa7E9n6ggyfxoHU0LPbNti5mSXWkfYwTEctWEvkw8fFd64YwmoU8coVe7lPEpZbA7E00D9VaXgdi");

export default function Checkout() {
    const [clientSecret, setClientSecret] = useState("");

    let [searchParams, setSearchParams] = useSearchParams();
    const param_secret = searchParams.get("client_secret");
    useEffect(() => {
        if (param_secret) {
            setClientSecret(param_secret)
        }
    }, [param_secret]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className="payment">
            <Flex style={{ height: '100vh', width: '100%' }} justify="center" align="center">
                {clientSecret ?
                    <div style={{ width: '500px' }}>
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </div> : <div></div>}

            </Flex>
        </div>
    );
}